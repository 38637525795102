import Section1 from "../../Components/layout/home/Section1/Conteiner";
import Section2 from "../../Components/layout/home/Section2/Conteiner";
import Section3 from "../../Components/layout/home/Section3/Conteiner";
export default function Home(){
    return(
        <>
        <Section1/>
        <Section2/>
        <Section3/>
        </>
    )

};