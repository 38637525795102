import React, { useState } from 'react';
import './Index.css';
import { NavLink, useNavigate } from 'react-router-dom';
import Logo from '../../../assets/logonavbar.png';

export default function Navbar() {
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
        toggleMenu();
    };

    const handleNavClick = (path, sectionId) => {
        if (path === "/") {
            scrollToSection(sectionId);
        } else {
            navigate(path);
        }
    };

    return (
        <header className={isOpen ? 'expanded' : ''}>
            <nav id='navbar' className="navbar">
                <img 
                    className="nav_logo" 
                    src={Logo} 
                    alt="Logo, 'Lohuan Defendendo o que é certo!' Logo feito com as cores preto e branco com a imagem da letra O com a bandeira do Brasil como ilustração"
                />
                <button id='mobile_btn' onClick={toggleMenu}>
                    &#9776;
                </button>
                <ul id='nav_list' className={`nav_list ${isOpen ? 'active' : ''}`}>
                    <li className="nav-item">
                        <NavLink exact to="/" activeClassName="active" onClick={toggleMenu}>Home</NavLink>
                    </li>
                    <li className="nav-item">
                        <a href="#prop" onClick={() => handleNavClick("/", "prop")}>Propostas</a>
                    </li>
                </ul>
                <button id='btn-nav' className="btn-default">Apoie aqui</button>
            </nav>
        </header>
    );
}
