import React from 'react';
import './Index_BandeiraG.css';
import cImage from '../../../../assets/c.png';

export default function BandeiraGrande() {
  return (
    <div className="shape">
      <div className="losango">
        <img src={cImage} alt="Banner" />
        <div className="circulo"></div>
      </div>
    </div>
  );
}
