import React from 'react';
import './Index.css';
import BandeiraG from '../../Bandeira/Bandeiras/BandeiraGrande';
import BandeiraP from '../../Bandeira/Bandeiras/BandeiraPequena';
import { useMediaQuery } from 'react-responsive';
import { FaWhatsapp, FaInstagram, FaFacebook } from 'react-icons/fa';

export default function Conteiner() {
  const isSmallScreen = useMediaQuery({ query: '(max-width: 999px)' });
  const isLargeScreen = useMediaQuery({ query: '(min-width: 1200px)' });

  return (
    <section id="home" className='home'>
      <div id="cta">
        <h1 className="title">
          Sorocaba nas <span>Alturas</span>
        </h1>
        <p className="description">
          O projeto 'Sorocaba nas Alturas' visa construir uma cidade mais integrada, funcional, empreendedora e sustentável. Nossa visão é que os novos empreendimentos não sejam apenas espaços habitacionais, mas sim parte integrante do ambiente urbano, impulsionando o desenvolvimento econômico e social da região. Ao promover a integração com a comunidade, o empreendedorismo local, a funcionalidade e sustentabilidade dos projetos, além da reintrodução do verde na cidade, estamos trabalhando para construir um futuro melhor e mais próspero para Sorocaba e seus moradores.
        </p>
        <div id="cta_buttons">
          <a href="#" className="btn-default">Grupo do Telegram</a>
          <a href="#" className="btn-default">Grupo do WhatsApp</a>
        </div>
        <div className="social-media-buttons">
          <a href="#"><FaWhatsapp /></a>
          <a href="#"><FaInstagram /></a>
          <a href="#"><FaFacebook /></a>
        </div>
        {isSmallScreen && <BandeiraP className="bandeira-pequena" />}
      </div>
      {isLargeScreen && <BandeiraG />}
    </section>
  );
}
