import React from 'react';
import './Index_BandeiraP.css';
import cImage from '../../../../assets/c.png';

export default function BandeiraPequena() {
  return (
    <div className="shape">
      <div className="losango pequena">
        <img src={cImage} alt="Banner" />
        <div className="circulo pequena"></div>
      </div>
    </div>
  );
}
