import './Index.css';
import { MdLocalHospital, MdOutlinePark } from 'react-icons/md';
import { FaHome, FaGraduationCap, FaBusAlt, FaPaintBrush, FaShieldAlt } from 'react-icons/fa';
import { GiSuspensionBridge } from 'react-icons/gi';

export default function propostas() {
   return(
      <div id='prop' className='container-propostas'>
        <h1 className="title">Visão para Sorocaba:</h1>
        <div className="cartao-proposta">
          <div className="secao-data">
            <p className="dia-proposta">Saúde</p>
            <p className="mes-proposta">Planos</p>
            <p className="icone-proposta"><MdLocalHospital /></p>
          </div>
          <div className="secao-data">
            <p className="dia-proposta">Segurança</p>
            <p className="mes-proposta">Saiba mais</p>
            <p className="icone-proposta"><FaShieldAlt /></p>
          </div>
          <div className="secao-data">
            <p className="dia-proposta">Moradia</p>
            <p className="mes-proposta">Saiba Mais</p>
            <p className="icone-proposta"><FaHome /></p>
          </div>
          <div className="secao-data">
            <p className="dia-proposta">Educação</p>
            <p className="mes-proposta">Saiba Mais</p>
            <p className="icone-proposta"><FaGraduationCap /></p>
          </div>
          <div className="secao-data">
            <p className="dia-proposta">Transporte</p>
            <p className="mes-proposta">Saiba Mais</p>
            <p className="icone-proposta"><FaBusAlt /></p>
          </div>
          <div className="secao-data">
            <p className="dia-proposta">Cultura e Lazer</p>
            <p className="mes-proposta">Saiba Mais</p>
            <p className="icone-proposta"><FaPaintBrush /></p>
          </div>
          <div className="secao-data">
            <p className="dia-proposta">Desenvolvimento Urbano e Infraestrutura</p>
            <p className="mes-proposta">Saiba Mais</p>
            <p className="icone-proposta"><GiSuspensionBridge /></p>
          </div>
          <div className="secao-data">
            <p className="dia-proposta">Meio Ambiente e Sustentabilidade</p>
            <p className="mes-proposta">Saiba Mais</p>
            <p className="icone-proposta"><MdOutlinePark /></p>
          </div>
        </div>
      </div>
   );
}
