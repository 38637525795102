import React from 'react';
import {BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home/Home';
import Novidades from './pages/Novidades/Novidades';
import Propostas from './Components/layout/home/Section3/Conteiner'
import Navbar from './Components/layout/Navbar/Navbar';
import './App.css'


export default function App() {
  return (

    <Router>
      <Navbar/>
      <Routes>
      <Route path="/" element={<Home />} />
        <Route path="/Propostas" element={<Propostas />} />
        <Route path="/Novidades" element={<Novidades />} />
      </Routes>
    </Router>
    
  );
}

