import React from "react";
import BracosCImage from '../../../../assets/Lohuan braços cruzados com blur.png';
import './Index.css';

export default function Conteiner() {
  return (
    <section id="section2" className='Section2'>
      <img src={BracosCImage} id="h-foto" alt="Imagem do candidato do Lohuan de braços cruzados" />
      <div id="h_video">
        <div id="h-text">
          <h1 className="title">Quem é <span>Lohuan Pietro</span></h1>
          <h3 className="section-subtitle">Conheça minha história pessoal e política</h3>
        </div>
        <iframe 
          width="100%" 
          height="500px" 
          src="https://www.youtube.com/embed/Er9VvPr2_uw?si=3txUN8zfj-ThkxYj" 
          title="YouTube video player" 
          frameBorder="0" 
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
          referrerPolicy="strict-origin-when-cross-origin" 
          allowFullScreen>
        </iframe>
      </div>
    </section>
  );
}
